<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀参加财联社圆桌论坛
              </p>
              <div class="fst-italic mb-2">2023年12月22日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >财联社投资年会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/69/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    12月15日，财联社第六届投资年会·财富管理分论坛在上海举行，非凸科技受邀出席圆桌论坛“AI如何赋能量化交易”，与行业专业人士就“AI技术下的行业变革”、“AI技术应用之挑战”等话题进行了深入细致的探讨。
                  </p>
                  <p>
                    今年以来，以ChatGPT为代表的主流人工智能技术在全球范围内掀起了AI浪潮。提及近年来AI技术对数智交易的赋能作用，非凸科技联合创始人&CTO李佐凡表示，作为AI的赋能方，非凸科技更多时候是一种赋能公募和私募交易的产生工具。比如，通过利用AI技术进行矩阵运算的最底层开发，我们成功加快了交易速度。此外，从策略研究的细节出发，AI的赋能还在于预测挂单概率以及价格偏离等方面，助力交易者更准确地制定决策，从而提高交易效果。
                  </p>
                  <p>
                    在过去几年里，生成式AI（AIGC）取得了显著的成长，生成的内容变得越来越真实。李佐凡表示，随着大模型和持续模型的发展，以及硬件和算力的支持，AIGC有助于更好地逼近真实的股票市场。此外，不论是内存、硬件还是显卡，很多公司都会面临各种报警和限制等问题。然而，这一问题也是整个数智交易行业面临的挑战，硬件的发展对于推动行业创新与发展具有积极的作用。
                  </p>
                  <p>
                    对谈中，国泰基金量化投资事业部基金经理吴中昊分享了数智行业变革。他表示，2016年以前市场野蛮生长，相关产品相对粗糙；2016年以后进入高频化时代，私募积累了非常多的高频因子，在很长一段时间内，交易非常有效，但同时陷入了比拼因子数量以及交易速度的激烈竞争。2018年以后，市场参与者纷纷大量进行AI研究，数智行业进入爆发阶段。
                  </p>
                  <p>
                    博道基金量化投资部总经理杨梦就AI技术对数智投资的助推作用发表了看法。她表示，机器学习的概念很广泛，可以应用于因子挖掘、因子选择、组合配全、组合生成、交易执行等多个投资环节。
                  </p>
                  <p>
                    鸣石基金高级副总裁房明表示，三年前，机构的因子总数量基本在上万级别，而三年后，因子总数量已接近10万等级，此时用AI挖掘出来的因子入库率、可靠性与三年前几乎一致。这意味着，针对偏高频的量化，AI的挖掘效果显著好于人工挖掘。
                  </p>
                  <p>
                    最后，主持人猫头鹰基金研究院院长矫健表示，分析式AI已被大规模成功应用，面临着一定挑战，购买到算力至关重要，此外，生成式AI的应用发展还有很长的路要走。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/69/02-合照.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    挑战与变化并存，合力让一切皆有可能。非凸科技也将积极参与国内AI生态建设，与合作伙伴共同探索，聚力于数智交易，把握现在，共创未来。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News69",
};
</script>

<style></style>
