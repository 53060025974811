<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                WAIC｜非凸科技受邀参展，揭秘AI与交易
              </p>
              <div class="fst-italic mb-2">2023年7月13日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >WAIC2023</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/44/01-WAIC.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    2023世界人工智能大会（WAIC）于7月6日-8日在上海圆满举行，首次引入“开源集市”多元融合创新活动，汇聚了全球技术大牛、AI大咖，展示了开源魅力，分享了技术成果。非凸科技受邀参展并做主题分享，期间收获高度关注。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/44/02-陆一洲.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    7月8日，非凸科技策略研究负责人陆一洲在WAIC开源集市开放麦上，从三个方面为大家揭秘“AI与交易”，深入浅出，通俗易懂。首先，陆一洲通过娃娃机工作原理，来解释什么是AI交易。当我们在交易时，不仅是股票或券商，还有交易中带来的胜率，以及所积累的相关信息价值，即“信息价值=时间成本+投资回报+娱乐收益”，所以为了提升我们所获得的投资回报，需要尽可能地去提升信息价值，缩短获取信息的时间，减少盲目冲动的行为，即“投资回报=信息价值-时间成本-娱乐收益”。此时，拥有强大信息收集效率，以及不受情绪干扰的AI，便是最佳的投资者。陆一洲表示，“AI已经博览全球市场每一个标的，处理着T级别的实时数据，构建了百档订单簿、千条K线，演算着上万种策略，甚至已经发出了计算后的交易决策，这就是AI交易。”
                  </p>
                  <p>
                    然而，在如此竞争激烈的交易市场中，人将如何胜过AI？陆一洲举例AlphaGo来说明人的学习效率远超AI，又举例股票GameStop来说明AI强于微观领域的规则与推演，而人善于宏观领域的关联与发展。他表示，“人应该聚焦于更擅长的宏观预测，给定有限的新闻或市场信息，分析能力与学习效率更胜一筹。除此之外，人还可驾驭AI，即具体的交易执行委托给算法交易，能以更低的成本价进行交易，与此同时，能将长期持有的股票委托给底仓增强算法，不断创造超额收益。这也是非凸科技的核心价值所在，对个人及机构投资者提供AI交易服务。”
                  </p>
                  <p>
                    那么，在AI时代，智能算法又如何长久长久占领一席之地呢？非凸科技有遍及国内外的办公室，有华尔街精英，有顶尖名校研究员，有数智交易领域大神，还有不断持续加码投入的超级计算机服务平台。陆一洲表示，“在AI时代，我们使用Rust可以省去70%的运维时间，保证了超强大脑能够稳固的应对更为广阔的未来。我们希望通过智能算法，帮助AI时代的人们聚焦于更擅长的宏观预测，实现人与AI的合作共赢。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/44/03-合集.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News44",
};
</script>

<style></style>
