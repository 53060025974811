<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                携手共赢！非凸科技与多家头部系统供应商合作
              </p>
              <div class="fst-italic mb-2">2022年11月23日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >系统供应商</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/21/01-合作.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，多家头部系统供应商向非凸科技发出邀约合作，聚焦数智交易服务生态圈展开深入交流。针对算法总线平台以及优质算法产品的落地，利用人工智能、大数据等先进技术，持续创新，共同助力机构客户在算法交易领域中时刻做好准备并取得佳绩。
                  </p>
                   <p>
                    非凸科技已与多家券商达成总对总合作，从功能到性能，从设计到架构，从技术迭代到服务升级，均获得高度认可。非凸智能算法上线以来，平均绩效皆稳居前列，领先市场平均水平。在提高交易效率、优化交易表现、降低交易风险等方面，都体现出无可替代的价值。
                  </p>
                  <p>
                    持续赋能，为券商带来更多便利。近年来，券商纷纷布局算法交易业务，在行情、交易、算法、策略等方面积极引入科技力量，但依然在引入流程上面临一些复杂业务对接不顺畅、管理成本比较高等问题。对此，非凸科技可以为券商提供测试服务器等其它便利服务，全方位保障业务的顺利开展。
                  </p>
                  <p>
                    基于数智交易领域的长期积累，以及完全自主的知识产权，非凸科技利用深度学习、强化学习等最新科技成果对金融信息进行深度分析和预测，采用多源异构的时空数据分析框架进行因子挖掘，利用机器学习技术进行模型参数调整和最优化建模，适应市场周期的变化。
                  </p>
                  <p>
                    除此之外，非凸科技针对不同客户，提供现场培训及长期咨询服务，帮助客户加深对智能算法与工具的理解，提升交易水平，改善投资绩效。
                  </p>
                  <p>
                    期待与更多券商合作，将金融科技力量真正赋能业务发展，在业务和技术深度协同的过程中，共同发展、实现卓越。
                  </p>
                 <p>
                    紧随金融科技浪潮，携手迎接挑战！非凸科技也将加强与行业各生态伙伴的合作，发挥各自最大优势，推动行业形成可持续发展的数智交易新生态。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News21",
};
</script>

<style></style>
