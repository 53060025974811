<template>
  <span class="icon" :style="{color: color || 'orange', borderColor: borderColor || 'orange'}">
  <slot> {{text}}</slot>
  </span>

</template>

<script>
export default {
  name: "Icon",
  props: {
    text: String,
    color: {
      type: String,
      default: "orange"
    },
    borderColor: {
      type: String,
      default: "orange"
    },
  }
}
</script>

<style scoped>
  .icon {
    position: relative;
    border-width: 1px;
    border-style: solid;
    letter-spacing: 0.1rem;
    display: inline-block;
    border-radius: 15%;
    font-size: 1rem;
  }
</style>
