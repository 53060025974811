<template>
  <div class="card h-100 shadow border-0">
    <img
      class="card-img-top"
      :src="require(`@/${element.img_src}`)"
      :alt="element.title"
    />
    <div class="card-body p-4">
      <div
        v-for="tag in element.tags"
        :key="tag"
        class="badge bg-primary bg-gradient rounded-pill me-2 mb-2"
      >
        {{ tag }}
      </div>
      <router-link
        class="text-decoration-none link-dark stretched-link"
        :to="element.link"
        v-if="!element.link.startsWith('http')"
        >
        <h5 class="card-title mb-3">{{ element.title }}</h5>
        </router-link>
        <a
        class="text-decoration-none link-dark stretched-link"
        :href="element.link" v-else
        >
        <h5 class="card-title mb-3">{{ element.title }}</h5>
      </a>

      <p class="card-text mb-0">{{ element.text }}</p>
    </div>
    <div class="card-footer p-4 pt-0 bg-transparent border-top-0">
      <div class="d-flex align-items-end justify-content-between">
        <div class="d-flex align-items-center">
          <img
            class="rounded-circle me-3 logo_40"
            :src="require(`@/${element.author_img_src}`)"
          />
          <div class="small">
            <div class="fw-bold">{{ element.author }}</div>
            <div class="text-muted">{{ element.date }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    element: Object,
  },
};
</script>

<style scoped>
.logo_40 {
  height: 40px;
}
</style>
