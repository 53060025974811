<template>
  <div>
    <div class="overflow-hidden">
          <div
            class="
              swiperContainer
              banner
              d-flex
              flex-column
            "
          >
          <div class="swiper-text">
            <div class="display-5 fw-bold mb-3">追求卓越算法 科技赋能投资</div>
            <div class="mb-5 text-secondary fw-light">
              <div >使用AI大模型等新质生产力，结合Rust工程化应用实践</div>
              <div>致力于为中小投资者提供一站式数智交易领域服务解决方案</div>
            </div>
            <router-link
              role="button"
              class="btn btn-primary link-light px-3 "
              to="/about/company"
              >了解更多</router-link
            >
          </div>
          </div>
        <!--</swiper-slide>
      </swiper>-->
    </div>
    <!-- Slogan -->
    <!-- 关于非凸-->
    <!-- <section class="py-5">
      <div class="container px-4">
        <div class="row gx-5 justify-content-center">
          <div class="col-lg-12 col-xl-12">
            <div>
              <h2 class="fw-bolder">关于非凸</h2>
            </div>
            <div class="mb-4 fs-3 mt-5 text-center d-none d-lg-block">
              <p>使用AI、大模型等新质生产力，结合Rust工程化应用实践</p>
              <p>致力于为中小投资者提供一站式数智交易领域服务解决方案</p>
              <div class="text-center mt-5">
                <router-link
                  role="button"
                  class="btn btn-primary link-light"
                  to="/about/company"
                  >了解更多</router-link
                >
              </div>
            

            </div>
            <div class="mb-4 fs-5 mt-5 text-center d-lg-none">
              <p>使用AI、大模型等新质生产力，结合Rust工程化应用实践</p>
              <p>致力于为中小投资者提供一站式数智交易领域服务解决方案</p>
              <div class="text-center mt-5">
                <router-link
                  role="button"
                  class="btn btn-primary link-light"
                  to="/about/company"
                  >了解更多</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row gx-5">
              <div class="mb-5 mb-lg-5">
                <h2>非凸算法生态</h2>
            </div>
            <div class="mb-1">
              <div class="mb-1">
                <img class="w-100" src="../assets/img/非凸算法生态.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 产品服务-->
    <section class="py-5">
      <div class="container px-4">
        <div class="row gx-5">
          <div class="mb-5 mb-lg-0">
            <h2 class="fw-bolder mb-0">产品服务</h2>
            <!-- p class="lead fw-normal text-muted mb-2">Products</p-->
          </div>
        </div>
        <div class="mt-5 row d-none d-lg-flex">
          <div class="col-4">
            <div
              class="
                accordion accordion-flush
                h-100
                d-flex
                flex-column
                justify-content-center
              "
              id="accordion-product"
            >
              <div class="accordion-item border-0">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button fs-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-algorithm"
                    aria-expanded="true"
                    aria-controls="collapse-algorithm"
                  >
                    非凸基础服务组件
                  </button>
                </h2>
                <div
                  id="collapse-algorithm"
                  class="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordion-product"
                >
                  <div class="accordion-body">
                    <div class="mb-3">
                      包括拆单算法、底仓增强算法、被动算法等，支持股票、转债及ETF
                    </div>
                    <!-- router-link to="/product/algorithm" class="text-decoration-none">查看更多</router-link-->
                  </div>
                </div>
              </div>
              <div class="accordion-item border-0 mt-4">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed fs-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-platform"
                    aria-expanded="false"
                    aria-controls="collapse-platform"
                  >
                    非凸智能交易终端
                  </button>
                </h2>
                <div
                  id="collapse-platform"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordion-product"
                >
                  <div class="accordion-body">
                    <div class="mb-3">
                      基于 Rust 生态打造智能交易系统，成为行业先驱
                    </div>
                    <!--router-link to="/product/platform" class="text-decoration-none">查看更多</router-link-->
                  </div>
                </div>
              </div>
              <div class="accordion-item border-0 mt-4">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed fs-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-底仓增强"
                    aria-expanded="false"
                    aria-controls="collapse-底仓增强"
                  >
                    非凸升级服务组件
                  </button>
                </h2>
                <div
                  id="collapse-底仓增强"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordion-product"
                >
                  <div class="accordion-body">
                    <div class="mb-3">
                      前瞻性自研开发的一站式AI金融终端<br />
                    </div>
                    <!--router-link to="/product/底仓增强" class="text-decoration-none">查看更多</router-link-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <img
              v-if="collapse === 0"
              class="collapse-image"
              src="../assets/img/home/基础服务组件.png"
              alt="非凸科技"
            />
            <img
              v-else-if="collapse === 2"
              class="collapse-image"
              src="../assets/img/home/非凸智能交易终端.png"
              alt="非凸科技"
            />
            <img
              v-else-if="collapse === 1"
              class="collapse-image"
              src="../assets/img/home/升级服务组件.png"
              alt="非凸科技"
            />
          </div>
        </div>
        <div class="row d-lg-none">
          <swiper
            ref="productSwiper"
            class="swiper overflow-visible"
            @slideChange="onProductSlideChange"
            :options="swiperOptionProduct"
          >
            <swiper-slide
              ><img
                class="collapse-image"
                src="../assets/img/home/基础服务组件.png"
                alt="非凸科技"
              />
            </swiper-slide>
            <swiper-slide
              ><img
                class="collapse-image"
                src="../assets/img/home/非凸智能交易终端.png"
                alt="非凸科技"
              />
            </swiper-slide>
            <swiper-slide
              ><img
                class="collapse-image"
                src="../assets/img/home/升级服务组件.png"
                alt="非凸科技"
            /></swiper-slide>
            <template v-slot:pagination>
              <div class="product-swiper-pagination text-center"></div>
            </template>
          </swiper>
          <div class="product-swiper-text mt-3">
            <div v-if="collapse == 0">
              <div class="accordion-body">
                <div class="fs-4">非凸基础服务组件</div>
                <div class="mb-3">
                  包括拆单算法、底仓增强算法、被动算法等，支持股票、转债及ETF
                </div>

                <!--router-link to="/product/algorithm">查看更多</router-link-->
              </div>
            </div>
            <div v-else-if="collapse == 1">
              <div class="accordion-body">
                <div class="fs-4">非凸智能交易终端</div>
                <div class="mb-3">
                  基于 Rust 生态打造智能交易系统，成为行业先驱
                </div>
                <!--router-link to="/product/platform">查看更多</router-link-->
              </div>
            </div>
            <div v-else-if="collapse == 2">
              <div class="accordion-body">
                <div class="fs-4">非凸升级服务组件</div>
                <div class="mb-3">
                  前瞻性自研开发的一站式AI金融终端
                </div>
                <!-- router-link to="/product/底仓增强">查看更多</router-link-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-4 life" style="background-color: #0785E0;min-height: 14rem;">
        <div class="row text-center lh-lg text-light my-4">
          <div class="col-md-4">
          <div class="fs-1 fw-bold">申请试用</div>
          </div>
          <div class="col-md-6">
          <div class="">申请方式：1. 联系客服，申请模拟盘试用；2. 获取 Guest 账户，客服配合客户测试</div>
          <div class="">Tel：153 8016 7930  </div>
           <a
            href="https://wj.qq.com/s2/11823137/a41b/"
            target="_blank"
            role="button"
             class="
              btn btn-outline-light mt-3 rounded-pill
              text-decoration-none me-3
            "
             >点击申请</a
          >
          
          <router-link
            target="_blank"
            role="button"
             class="
              btn btn-outline-light mt-3 rounded-pill
              text-decoration-none
            "
            to="/product/Platform"
            >试用指南</router-link
          >
          </div>
        </div>
        </section>
    <!--非凸智能引擎-->
    <!--section class="py-5">
      <div class="container px-4">
        <div class="row gx-5 justify-content-center">
          <div class="col-lg-12 col-xl-12">
            <div>
              <h2 class="fw-bolder">非凸智能引擎</h2>
              <p class="lead fw-normal text-muted">Smart Engine</p>
            </div>
            <div class="mb-5">
              <div class="mb-5">
                <img class="w-100" src="../assets/img/structure.jpg" />
              </div>
              <div class="row g-4 text-center">
                <div class="col-3">
                  <div class="d-none d-md-block fs-3">低成本</div>
                  <div class="d-block d-md-none">低成本</div>
                </div>
                <div class="col-3">
                  <div class="d-none d-md-block fs-3">低延时</div>
                  <div class="d-block d-md-none">低延时</div>
                </div>
                <div class="col-3">
                  <div class="d-none d-md-block fs-3">高效率</div>
                  <div class="d-block d-md-none">高效率</div>
                </div>
                <div class="col-3">
                  <div class="d-none d-md-block fs-3">高可靠</div>
                  <div class="d-block d-md-none">高可靠</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section-->
    <!-- Career -->
    <section class="">
        <div class="container py-5 mb-5">
          <div class="row text-center gy-5" style="min-height: 20rem">
           <div class="col-md-4 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">200+</div>
              <div>团队规模</div>
            </div>
            <div class="col-md-4 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">10+</div>
              <div>核心成员10年以上从业经验</div>
            </div>
            <div class="col-md-4 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">95%+</div>
              <div>研发团队毕业于国内外知名院校</div>
            </div>
            <div class="col-md-4 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">150亿+</div>
              <div>智能交易终端日均成交额</div>
            </div>
            <div class="col-md-4 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">30+</div>
              <div>发明专利/软件著作权</div>
            </div>
            <div class="col-md-4 d-flex flex-column justify-content-center">
              <div class="color-default" style="font-size: 4rem">20+</div>
              <div>ACM/ICPC World Final 等国际奖项</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
           <div class="row mt-2 mb-5">
             <div class="col-md-3 ml-auto">
                 <h2 class="fw-bolder">核心竞争力</h2>
             </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../assets/img/home/极致技术.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">极致技术</h3>
                    <p class="text-dark text-left">
                      Rust 全栈开发，极大提升研发效能；纳秒级延时优化，带来最大化收益
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../assets/img/home/AI交易逻辑.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">AI 交易逻辑</h3>
                    <p class="text-dark text-left">
                      融合多种机器学习模型，精细捕捉市场信号，自动完成海量交易，稳健获取超额收益
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../assets/img/home/自建算力集群.png"/>
                  </div>
                  <div class="media-body">
                    <h4 class="h4">自建算力集群</h4>
                    <p class="text-dark text-left">
                      每年千万级算力投入，自建AI超算中心，实现模型及策略高效迭代，保证算法持续有效
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../assets/img/home/专业服务.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">专业服务</h3>
                    <p class="text-dark text-left">
                      极速响应客户，满足定制化需求；持续迭代更新，适应新的市场变化
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <!--section class="py-5">
      <div class="container mb-3">
        <div
          class="row text-center fs-1 d-flex justify-content-center mb-3 gy-5"
        >
          <p>职业发展</p>
        </div>
        <div class="row g-5 px-2">
          <div class="col-md-6">
            <img
              src="../assets/img/home/career.jpg"
              alt="非凸科技"
              class="w-100 h-100"
            />
          </div>
          <div class="col-md-6 d-flex flex-column justify-content-center px-5">
            <div class="fs-5 pb-4">
              非凸在上海、北京、深圳、成都、新加坡、美国均开放招聘岗位，欢迎热爱数学、计算机、Rust 的你，加入我们！
            </div>
            <div class="text-left">
              <router-link
                role="button"
                class="btn btn-outline-primary"
                to="/join#career"
                >现在申请</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section-->
    
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
           <div class="row mt-2 mb-5">
             <div class="col-md-3 ml-auto">
                 <h2 class="fw-bolder">荣誉认证</h2>
             </div>
            </div>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-5 gy-4">
            <div class="plan col">
              <img
                src="../assets/img/home/专精特新.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">上海市专精特新企业</div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../assets/img/home/02-高新.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">国家高新技术企业</div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../assets/img/home/人工智能行业协会.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">人工智能行业协会会员单位</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>

      <section class="d-flex flex-column mt-6 bg-light">
        <div class="py-5">
          <div class="container-lg px-4">
           <div class="row mt-2 mb-5">
             <div class="col-md-3 ml-auto">
                 <h2 class="fw-bolder">核心成员</h2>
             </div>
            </div>
          <div class="row mt-5">
            <div
              class="
                flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center
              "
            >
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">李佐凡</p>
                <div class="hr"></div>
                <p class="mb-3">联合创始人 & CTO</p>
                <p class="fst-italic">宾夕法尼亚大学</p>
                <p class="fst-italic">上海交通大学 ACM 班</p>
                <p class="fst-italic">曾就职 BlackRock</p>
                <p class="fst-italic">12 年金融行业从业经验</p>
              </div>
            </div>
            <div class="flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">王浚澎</p>
                <div class="hr"></div>
                <p class="mb-3">联合创始人 & CEO</p>
                <p class="fst-italic">大连理工大学</p>
                <p class="fst-italic">新加坡知名基金联合创始人</p>
              </div>
            </div>
            <div class="flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">郑媛姿</p>
                <div class="hr"></div>
                <p class="mb-3">首席运营官</p>
                <p class="fst-italic">考文垂大学</p>
                <p class="fst-italic">专注企业长远战略规划</p>
                <p class="fst-italic">负责公司整体运营</p>
              </div>
            </div>
            <div class="flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">朱为</p>
                <div class="hr"></div>
                <p class="mb-3">北京分公司合伙人</p>
                <p class="fst-italic">华中科技大学</p>
                <p class="fst-italic">10 年互联网资深架构师</p>
              </div>
            </div>
            <div class="flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">乔丹</p>
                <div class="hr"></div>
                <p class="mb-3"> 首席架构师</p>
                <p class="fst-italic">北京大学</p>
                <p class="fst-italic">百度等互联网公司10年从业经验</p>
                <p class="fst-italic">专注于低延迟交易系统架构设计与开发</p>
              </div>
            </div>
            <div class="flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">曹迅</p>
                <div class="hr"></div>
                <p class="mb-3">新加坡分公司合伙人</p>
                <p class="fst-italic">大连理工大学</p>
                <p class="fst-italic">新加坡知名团队核心架构师</p>
              </div>
            </div>
            <div class="flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">曹靖康</p>
                <div class="hr"></div>
                <p class="mb-3">深圳分公司合伙人</p>
                <p class="fst-italic">武汉大学</p>
                <p class="fst-italic">多年工程实践及策略研究经验</p>
              </div>
            </div>
             <div class="flex-row card bg-light text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">Harold</p>
                <div class="hr"></div>
                <p class="mb-3">产品总监</p>
                <p class="fst-italic">中国矿业大学</p>
                <p class="fst-italic">7 年金融从业经验</p>
                <p class="fst-italic">专注于打造优质算法产品</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>

    <section class="">
        <div class="py-5">
          <div class="container-lg px-4">
           <div class="row mt-2 mb-5">
             <div class="col-md-3 ml-auto">
                 <h2 class="fw-bolder">合作方式</h2>
             </div>
            </div>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-5 gy-4">
            <div class="plan col">
              <img
                src="../assets/img/home/ways-01.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">研究·开发</div>
                <div class="text-dark py-3">
                  <p>我们将团队聚集一起，为您探讨最优的合作方案，并负责从设计到交付的所有事项</p>
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../assets/img/home/ways-02.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">迭代·优化</div>
                <div class="text-dark py-3">
                  我们将与您展开沟通顺畅的深度合作，持续迭代策略、优化参数，满足您的需求
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../assets/img/home/ways-03.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="h4 mt-4">结果·共赢</div>
                <div class="text-dark py-3">
                  我们核心目标是长期稳定打败基准、超越对手，获取超额收益，与您携手共赢
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>

    <section class="py-5 bg-light">
      <div class="container px-4">
        <div class="mb-2 mb-lg-0">
          <h2 class="fw-bolder">共商共赢</h2>
        </div>
        <div class="row d-lg-flex gy-5 mt-2">
           <div class="mt-5 col-md-8">
            <img
              src="../assets/img/home/共商共赢.jpg"
              alt="非凸科技"
              class="w-100 h-100"
            />
          </div>
          <div class="col-md-3 offset-md-1 d-flex flex-column justify-content-center">
            <div class="fs-5 pb-4">
              我们是一个值得信赖的名字，拥有算法表现持续高绩效的记录；我们希望寻找行业内有影响力的合作伙伴，发挥各自潜力，解决最难挑战。
            </div>
            <div class="text-left">
              <router-link
                role="button"
                class="btn btn-primary link-light"
                to="/business"
                >商务合作</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5">
      <div class="container px-4">

        <div class="mb-2 mb-lg-0">
          <h2 class="fw-bolder">职业发展</h2>
        </div>
        <div class="row d-lg-flex gy-5 mt-2">

           <div class="mt-5 col-md-8">
            <a
                href="/about/campus#intern"
                >
                <img
              src="../assets/img/home/职业发展.jpg"
              alt="非凸科技"
              class="w-100 h-100"
            />
                
                </a
              >

          </div>
          <div class="col-md-3 offset-md-1 d-flex flex-column justify-content-center">
            <div class="fs-5 pb-4">
              非凸在上海、北京、深圳、成都、徐州、新加坡、美国均开放招聘岗位，欢迎热爱数学、计算机、Rust 的你，加入我们！
            </div>
            <div class="text-left">
              <router-link
                role="button"
                class="btn btn-primary link-light"
                to="/join#career"
                >现在申请</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-5 bg-light">
      <div class="container px-4">
        <div class="mb-2 mb-lg-5">
          <h2 class="fw-bolder">新闻动态</h2>
        </div>
        <div class="row gx-5">
          <div class="col-md-4 mb-5" v-for="element in news" :key="element.id">
            <NewsCard :element="element"></NewsCard>
          </div>
        </div>
        <div class="text-center">
          <router-link
            role="button"
            class="btn btn-primary link-light"
            to="/about/news"
            >更多动态</router-link
          >
        </div>
      </div>
    </section>

     <!--section class="pt-5 life" style="background-color: #333333;">
        <div class="text-center lh-lg text-light my-4">
          <div class="fs-1 fw-bold">申请试用</div>
          <div class="">申请方式：1. 联系客服，申请模拟盘试用；2. 获取 Guest 账户，客服配合客户测试</div>
          <div class="">推荐福利：若成功推荐，则推荐者/被推荐者均获取 1W 代金劵，可抵扣产品服务费</div>
          <div class="">Tel：199 8461 3622</div>
          <a
            href="/Contact-customer-service.jpg"
            target="_blank"
            role="button"
             class="
              btn btn-outline-light mt-3 rounded-pill
              text-decoration-none
            "
            >联系我们</a
          >
        </div>
        </section-->
    <!--section class="bg-light pt-5 life anything">
        <div class="text-center lh-lg text-light my-5">
          <div class="fs-1 fw-bold">寻求合作</div>
          <div class>我们是一个值得信赖的名字，拥有算法表现持续高绩效的记录；我们希望寻找数智交易领域有影响力的合作伙伴，发挥各自潜力，解决最难挑战。</div>
          <router-link
            role="button"
            to="/team"
            class="
              btn btn-outline-light mt-3 rounded-pill
              text-decoration-none
            "
            >核心团队</router-link
          >
        </div>
        </section-->
    <!--section class="container-lg px-4 py-5 my-5" style="min-height: unset">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-3 location active" @mouseenter="onHover">
          <div class="background shanghai"></div>
          <div class="text">
            <div class="title">
              <div>上海</div>
            </div>
            <div class="sub-title">SHANGHAI</div>
          </div>
        </div>
        <div class="col-12 col-lg-3 location" @mouseenter="onHover">
          <div class="background beijing"></div>
          <div class="text">
            <div class="title">
              <div>北京</div>
            </div>
            <div class="sub-title">BEIJING</div>
          </div>
        </div>

        <div class="col-12 col-lg-3 location" @mouseenter="onHover">
          <div class="background chengdu"></div>
          <div class="text">
            <div class="title position-relative">
              <div>成都</div>
            </div>
            <div class="sub-title">CHENGDU</div>
          </div>
        </div>
      </div>
    </section-->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import NewsCard from "../components/NewsCard.vue";

export default {
  name: "Home",
  data() {
    return {
      news:[],
      notTouched: false,
      collapse: 0,
      swiperOptionProduct: {
        pagination: {
          el: ".product-swiper-pagination",
        },
        spaceBetween: 30,
      },
      swiperOptionHome: {
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
       },
       swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    NewsCard,
  },
  computed: {
    productSwiper() {
      return this.$refs.productSwiper.$swiper;
    },
    isWeixin() {
      var ua = navigator.userAgent.toLowerCase();
      return (
        ua.match(/MicroMessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == null
      );
    },
    isAndroid() {
      return navigator.userAgent.match(/android/i);
    },
  },
  methods: {
    onHover(event) {
      document.querySelectorAll(".location").forEach((ele) => {
        ele.classList.remove("active");
      });
      event.target.classList.add("active");
    },
    onProductSlideChange() {
      this.collapse = this.productSwiper.activeIndex;
    },
  },
  mounted() {
    this.news = [
      {
        id: 1,
        title: "非凸联合创始人李佐凡：将“科技赋能投资”作为自己的终身事业",
        text: "我们希望与行业同频呼吸，愿意尝试，敢于试错，让中国自己的数智交易实现跨越式发展。",
        tags: ["人物", "联合创始人"],
        img_src: "assets/img/news/10/01-李佐凡.jpg",
        author: "非凸人物",
        date: "2022年6月24日",
        link: "news10",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 2,
        title: "第49届ICPC亚洲区域赛·南京站，非凸科技持续支持赛事举办",
        text: "人才是持续推动科学技术进步的关键，非凸科技将持续支持赛事的举办，为国际竞赛界输送企业知识和众多资源。",
        tags: ["新闻", "ICPC"],
        img_src: "assets/img/news/93封面.jpg",
        author: "非凸新闻",
        date: "2024年11月11日",
        link: "https://mp.weixin.qq.com/s/zmz5QclQ3areF0Gk98LqQg",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 3,
        title: "非凸科技助力第49届ICPC亚洲区域赛（成都）成功举办",
        text: "期待与产学研各界专家、青年才俊一起，推动基础科学理论研究的重大突破，促进技术应用创新，共同为全球繁荣发展贡献力量。",
        tags: ["新闻", "ICPC"],
        img_src: "assets/img/news/92封面.jpg",
        author: "非凸新闻",
        date: "2024年11月4日",
        link: "https://mp.weixin.qq.com/s/6bIWT-VroFFPN5otHPvPDQ",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 4,
        title: "非凸科技银牌赞助GOSIM CHINA 2024，并受邀出席Rust分论坛",
        text: "与160+国内外行业领袖、技术专家，1000+一线开发者、开源人才，共同探讨开源生态系统的最新进展与未来趋势。",
        tags: ["新闻", "开源技术盛会"],
        img_src: "assets/img/news/91封面.jpg",
        author: "非凸新闻",
        date: "2024年10月23日",
        link: "https://mp.weixin.qq.com/s/oEcmPOUBfX7uqDo_Tdt0MA",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 5,
        title: "非凸算法全面上线国融证券",
        text: "非凸智能底仓增强算法、拆单算法顺利上线国融证券，全面满足投资者在多种终端上的智能交易服务需求。",
        tags: ["新闻", "国融证券"],
        img_src: "assets/img/news/90/封面.jpg",
        author: "非凸新闻",
        date: "2024年10月11日",
        link: "news90",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 6,
        title: "非凸智能算法全面上线中金公司",
        text: "全新升级，平稳运行，全方位满足投资者降低交易成本、优化交易执行、提升交易效率等切实需求。",
        tags: ["新闻", "中金公司"],
        img_src: "assets/img/news/89/封面.jpg",
        author: "非凸新闻",
        date: "2024年9月20日",
        link: "news89",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
    ];

    var scope = this;
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        let video = document.getElementById("video");
        if (video != null && video != undefined) {
          document.getElementById("video").play();
        }
      },
      true
    );
    document.addEventListener(
      "touchstart",
      function () {
        let video = document.getElementById("video");
        if (video != null && video != undefined) {
          scope.notTouched = false;
          document.getElementById("video").play();
        }
      },
      true
    );

    document
      .getElementById("collapse-algorithm")
      .addEventListener("show.bs.collapse", function () {
        scope.collapse = 0;
      });

    document
      .getElementById("collapse-底仓增强")
      .addEventListener("show.bs.collapse", function () {
        scope.collapse = 1;
      });

    document
      .getElementById("collapse-platform")
      .addEventListener("show.bs.collapse", function () {
        scope.collapse = 2;
      });
  },
};
</script>

<style lang="scss" scoped>
.anything {
  background-size: cover;
      background-position: center;background-image:url('~@/assets/img/04.jpg')
    }
section {
  overflow: hidden;
}
section.blue {
  color: $--ft-white;
  background-color: $--ft-primary-1;
}
.feature {
  display: inline-flex;
  align-items: center;

  justify-content: center;
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
}

.logo_50 {
  width: 50px;
}
#infinity {
  position: absolute;
  width: 720px;
  height: 600px;
  top: -4rem;
  left: calc(50% - 360px);
  z-index: 0;
}

#infinity:before,
#infinity:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 360px;
  height: 360px;
  border: 120px solid #f1f1f1;
  -moz-border-radius: 300px 300px 0 300px;
  border-radius: 300px 300px 0 300px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#infinity:after {
  left: auto;
  right: 0;
  border-radius: 300px 300px 300px 0;
  -moz-border-radius: 300px 300px 300px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.location {
  height: 200px;
  transform: skewX(-10deg);
  overflow: hidden;
}
.location.active {
  width: calc(200% / 5);
}
.location,
.location .background,
.location .text {
  transition: all ease-out 0.3s;
}

.location > div {
  transform: skewX(10deg);
  transform-origin: bottom;
}

.location > .background {
  background-size: cover;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 30rem;
}
.location.active .background {
  width: 45rem;
  opacity: 0.45;
}
.beijing {
  background-image: url("../assets/img/teams/beijing.jpg");
}
.shanghai {
  background-image: url("../assets/img/teams/shanghai.jpg");
}
.chengdu {
  background-image: url("../assets/img/teams/chengdu.jpg");
}
.location .text {
  text-align: center;
  color: white;
  position: relative;
  top: 2.5rem;
  width: fit-content;
  margin: auto;
  font-weight: 800;
}
.location.active .text {
  color: black;
  cursor: default;
}
.location .title {
  font-size: 3.5rem;
  display: inline-block;
  filter: drop-shadow(6px 6px 2px black);
}
.location .title a {
  color: unset;
}
.location.active .title {
  filter: none;
}
.video-section {
  background-color: black;
  margin-top: -8rem;
  min-height: unset;
}
.video-section video {
  width: 100%;
  object-fit: fill;
}
.accordion-button:not(.collapsed) {
  box-shadow: unset;
  background-color: unset;
}
.accordion-button.collapsed {
  opacity: 0.65;
}
.accordion-button:after {
  display: none;
}
.collapse-image {
  width: 100%;
  height: 100%;
}
.accordion * {
  background: unset;
}
.swiperContainer {
  min-height: 708px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;

  &.banner {
    background-image: url("../assets/img/home/banner.png");
  }
  .swiper-text {
    z-index: 1;
    padding-left: 240px;
  }
}
.plan img {
  width: 8rem;
}
.plan {
  text-align: center;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.card {
  border: none;
  border-radius: 0;
}
.card p {
  font-size: 1rem;
  font-weight: normal;
  color: $--ft-gray;
  margin-bottom: 0;
}
.card > div {
  width: 15rem;
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .location {
    position: relative;
    transform: none;
  }
  .location.active {
    width: 100%;
  }
  .location > div {
    transform: none;
  }
  .video-section {
    margin-top: 0;
    padding-top: 5rem;
  }
  .swiperContainer {
    min-height: 400px !important;
    justify-content: end;
    .swiper-text {
      padding-left: 10px;
      width:calc(9rem + 15vw);
      font-size: 1rem !important;
    }
  }
}
</style>
