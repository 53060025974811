<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                受邀参加国信证券主题活动，探讨数智交易如何助力投资者应对市场挑战
              </p>
              <div class="fst-italic mb-2">2024年6月7日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国信证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/82/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    5月30日，非凸科技受邀参加国信证券深圳分公司举办的“宏观经济趋势与资本市场演化”主题活动，与投顾专家、投资者共同探讨如何在复杂多变的投资环境下应对机遇与挑战，以及如何更好地为投资者提供全链路数智化交易解决方案。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/82/02-国信.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    会上，国信证券投资顾问许仁泽表示，2024年宏观经济趋势和资本市场将经历结构性出清，制造业将成为经济主导力量，周期性行业将占据重要地位，建议持续关注A股市场。
                  </p>
                  <p>
                    随后，非凸科技机构业务经理金言从算法策略、技术架构、投资效率等方面进行了深度分享。在中国金融市场开放化、数智化、机构化的发展浪潮中，非凸科技始终坚持技术为本、服务为先，以Rust全栈技术、AI算法力、金融理解力为核心驱动，构建新一代全业务数智交易平台，为客户提供多元化的技术解决方案。以客户需求为导向，非凸科技将协同国信证券持续通过金融科技力量真正赋能业务开展，在业务、技术互动过程中，与客户共同发展、实现卓越。
                  </p>
                  <p>
                    未来，非凸科技将持续加大技术生态投入，构建全栈自主创新支撑能力，助力更多投资者实现投资目标，创造更大的价值。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News82",
};
</script>

<style></style>
