<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>来非凸，拥抱每一种可能！</div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section>
        <div class="container-lg px-4 py-5 text-center">
          <img
            src="../../assets/img/join/campus-poster.jpg"
            class="poster"
            alt="非凸科技校园招聘"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialPoster",
};
</script>

<style scoped lang="scss">
.container-lg {
  border-radius: 2rem;
}
.icon {
  font-size: 4rem;
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/social_banner.jpg");
  background-position-x: 25%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.poster {
  max-width: 100%;
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .poster {
    width: 100%;
  }
}
</style>
