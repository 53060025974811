<template>
  <div>
    <div id="background">
      <div class="bottom d-flex justify-content-center gx-5">
        <div class="fs-5 scroll-down">
          <i class="bi bi-chevron-double-down"></i>
        </div>
      </div>
    </div>
    <div id="waves"></div>
    <div class="container-lg px-4">
      <div class="cover-text animation">
        <h1 class="user-select-none">非凸科技</h1>
        <h2 class="user-select-none">国内领先的智能算法和交易系统服务商</h2>
      </div>
      <div class="welcome">
        <p class="text">WELCOME TO NON-CONVEX</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import circle from "../assets/img/sphere-basic-white.png";

const SEPARATION = 100,
  AMOUNTX = 50,
  AMOUNTY = 50;

let container, camera, scene, renderer;
let particles,
  particle,
  count = 0;
let mouseX = -720;
let mouseY = -250;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

export default {
  name: "Waves",
  data() {
    return {};
  },
  methods: {
    init: function () {
      container = document.getElementById("waves");

      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        100,
        10000
      );
      camera.position.z = 1000;

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x0260a8);
      particles = new Array();

      var material = new THREE.SpriteMaterial({
        map: new THREE.TextureLoader().load(circle),
      });
      var i = 0;

      for (var ix = 0; ix < AMOUNTX; ix++) {
        for (var iy = 0; iy < AMOUNTY; iy++) {
          particle = particles[i++] = new THREE.Sprite(material);
          particle.position.x = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
          particle.position.z = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
          scene.add(particle);
        }
      }

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      /* document.addEventListener("mousemove", this.onPointerMove, false); */
      window.addEventListener("resize", this.onWindowResize, false);

    },
    onDocumentTouchStart: function (event) {
      if (event.touches.length === 1) {
        /* event.preventDefault(); */

        mouseX = event.touches[0].pageX - windowHalfX;
        mouseY = event.touches[0].pageY - windowHalfY;
      }
    },
    onDocumentTouchMove: function (event) {
      if (event.touches.length === 1) {
        /* event.preventDefault(); */

        mouseX = event.touches[0].pageX - windowHalfX;
        mouseY = event.touches[0].pageY - windowHalfY;
      }
    },
    onWindowResize: function () {
      windowHalfX = window.innerWidth / 2;
      windowHalfY = window.innerHeight / 2;

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    },
    onPointerMove: function (event) {
      if (event.isPrimary === false) return;

      mouseX = event.clientX - windowHalfX;
    },
    render: function () {
      camera.position.x += (mouseX - camera.position.x) * 0.05;
      camera.position.y += (-mouseY - camera.position.y) * 0.05;
      camera.lookAt(scene.position);

      var i = 0;

      for (var ix = 0; ix < AMOUNTX; ix++) {
        for (var iy = 0; iy < AMOUNTY; iy++) {
          particle = particles[i++];
          particle.position.y =
            Math.sin((ix + count) * 0.3) * 50 +
            Math.sin((iy + count) * 0.5) * 50;
          particle.scale.x = particle.scale.y =
            (Math.sin((ix + count) * 0.3) + 1) * 4 +
            (Math.sin((iy + count) * 0.5) + 1) * 4;
        }
      }

      renderer.render(scene, camera);

      count += 0.05;
    },
    animate: function () {
      requestAnimationFrame(this.animate);
      this.render();
    },
  },
  mounted() {
    this.init();
    this.animate();
  },
};
</script>

<style scoped lang="scss">
#waves {
  background: transparent;
  z-index: 1;
}
.cover-text {
  top: 20%;
  padding-right: 2rem;
  position: absolute;
}
.welcome {
  position:absolute;
  right: 1rem;
  bottom: 2rem;
}
.welcome > .text {
  color: white;
  font-weight: bolder;
  font-size: 1rem;
  font-family: monospace;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  text-orientation: upright;
}
.bottom {
  position: absolute;
  width: 100%;
  bottom: 20px;
  color: white;
}
.bottom > .line {
  background-color: white;
  height: 1px;
  width: 200px;
  align-self: center;
}
@media screen and (max-height: 500px) {
  .welcome {
    display: none;
  }
}
</style>
