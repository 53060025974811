<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>来非凸，拥抱每一种可能！</div>
        </div>
      </div>
    </div>
    <div class="container-lg px-4 content py-5">
      <div class="col-12">
        <a
          @click="$router.go(-1)"
          role="button"
          class="btn btn-outline-primary mb-3"
        >
          返回列表
        </a>
      </div>
      <div class="col-12 title fs-1 fw-bold">策略研究员</div>
      <div class="section mt-3">
        <div class="badge border text-primary border-primary">策略</div>
        <div class="ms-2 badge border text-primary border-primary">
          校园招聘
        </div>
      </div>
      <div class="section mt-3">
        <div class="fs-4 fw-bold my-4">招聘对象</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              2025届海内外高校毕业生
            </li>
          </ul>
        </div>
      </div>

      <div class="section mt-3">
        <div class="fs-4 fw-bold my-4">岗位描述</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              开发和设计中选股因子，构建选股模型；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              在多因子底仓上，开发设计股票策略；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              其他以绝对收益为目标的投资策略，如风格轮动策略、股指期货策略、商品期货策略、套利策略等。
            </li>
          </ul>
        </div>
      </div>

      <div class="section mt-3">
        <div class="fs-4 fw-bold my-4">岗位要求</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              2025届本科及以上学历，金融、物理、数学、计算机等理科背景；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              扎实的数学、统计基础，熟悉统计建模、时间序列模型、机器学习算法的原理及其编程实现；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              至少熟悉一种编程语言：Rust/C++/Python/Go/Java；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              对策略研究充满热情，思考深入，自我驱动，能快速学习新鲜事物。
            </li>
          </ul>
        </div>
      </div>

      <div class="section mt-3">
        <div class="fs-4 fw-bold my-4">工作城市</div>
        <div class="mt-2">
          <span> 上海 </span>
          <span> 北京 </span>
          <span> 深圳 </span>
          <span> 成都 </span>
          <span> 徐州 </span>
        </div>
      </div>

      <div class="section mt-3">
        <div class="fs-4 fw-bold my-4">投递简历</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              标题格式：2025校招-姓名-毕业院校-专业-岗位名称
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              邮件内容：可以充分自由发挥，最后附上个人简历
            </li>
          </ul>
        </div>
      </div>

      <div class="section mt-3">
        <div class="fs-4 fw-bold my-4">联系方式</div>
        <div class="mb-2">简历请发送至邮箱：recruit@ft.tech</div>
        <img
          src="../../assets/img/招聘求职.jpg"
          align="center"
        />
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <a class="btn btn-primary px-5" href="mailto:recruit@ft.tech">
          简历投递
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Job04",
};
</script>
<style scoped lang="scss">
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/campus_banner.jpg");
  background-position-x: 20%;
}
.container-lg {
  border-radius: 3rem;
}
a.btn {
  border-radius: 2rem;
}
img {
  width: 100px;
}
.cover-text > div {
  font-size: 3rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    text-align: center;
  }
}
</style>
